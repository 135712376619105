/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}

:root {
  --brand-black: #1a2227;
  --brand-yellow: #fde83c;
  --background-colour: #fff;
  --brand-light-grey: #e2e6ec;
  --brand-light-grey-2: rgba(226, 230, 236, 0.5);
  --brand-green: rgb(0,218,117);
  --brand-green-2: rgb(0,173,93);
  --brand-green-grey: rgb(152,161,153);
  --border-color: var(--brand-light-grey);
  --cta-colour: var(--brand-green);
  --cta-hover-colour: var(--brand-green-2);

  --bold-weight: 700;
  --hero-bottom-offset: 7em;
  --section-padding: 15px 0 20px 0;

  --text-colour: var(--brand-black);
  --action-link-boxshadow: 0px 1px 0px 0px var(--brand-black);
}

html {
  color: var(--text-colour);
  background-color: var(--background-colour);
  font-size: 1em;
  font-family: 'Poppins', sans-serif;
  line-height: 1.4;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--section-padding, 0);
  background-color: var(--background-colour);
}

#header {
  position: sticky;
  top: 0;
  z-index: 1;
}

button {
  width: 60%;
  left: 20%;
  position: relative;
  margin-bottom: 1em;
  padding: 1em;
  border: 3px solid var(--brand-green);
  border-radius: 5px;
  cursor: pointer;
  max-width: 15em;
}

button:hover {
  background-color: var(--brand-green);
}

/* firefox: no :has support at time of writing, Jan 2023
  a:has(button):hover,
  a:has(button) {
*/
.a-button:hover,
.a-button {
  box-shadow: unset;
}

.cta {
  background-color: var(--cta-colour);
}

.cta:hover {
  background-color: var(--cta-hover-colour);
  border: 3px solid var(--cta-hover-colour);
}

#hero {
  padding: unset;
  background-image: url("../img/hero.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-text {
  background-color: var(--background-colour);
  top: 2em;
  position: inherit;
  display: grid;
  place-content: center;
  left: 10%;
  margin-top: clamp(150px, min(150px, 15%), 15%);
  width: 80%;
  max-width: 24em;
  box-shadow: rgb(0 0 0 / 50%) 5px 5px 15px;
}

.hero-text h1 {
  line-height: 1em;
  letter-spacing: -1px;
  margin: 0.7em;
}

button p,
.cta p {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  overflow-wrap: break-word;
}

@media only screen and (min-width: 480px) {
  #hero {
    background-position-y: 25%;
    margin-bottom: 0;
    padding-bottom: var(--hero-bottom-offset);
  }

  .hero-text {
    margin-bottom: 3em;
    margin-top: 6em;
  }

  #hero-rule {
    display: none;
  }
}

@media only screen and (max-width: 479px) {
  #hero {
    margin-bottom: calc(var(--hero-bottom-offset) + 4em);
    max-height: 10em;
  }
}

@media only screen and (max-width: 348px) {
  #hero {
    margin-bottom: calc(var(--hero-bottom-offset) + 5em);
  }
}

@media only screen and (max-width: 285px) {
  #hero {
    margin-bottom: calc(var(--hero-bottom-offset) + 7em);
  }
}

footer {
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

nav {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-left: 1em;
  padding-right: 1em;
}

a {
  text-decoration: none;
  box-shadow: var(--action-link-boxshadow);
  font-weight: var(--bold-weight);
  color: inherit;
  transition: 250ms ease-in-out box-shadow;
}

#home-link {
  box-shadow: unset;
}

.current-page,
a:active,
a:hover,
#home-link:hover {
  --action-link-boxshadow: 0px 3px 0px 0px var(--brand-yellow);
  box-shadow: var(--action-link-boxshadow);
}

#nav-list {
  display: flex;
  align-items: center;
  column-gap: 1em;
  display: none;
}

#nav-list button {
  position: revert;
  margin-bottom: revert;
  padding: 0.25em;
}

/* firefox: no :has support at time of writing, Jan 2023
#nav-list a:has(button) {
*/
#nav-list .a-button {
  display: contents;
}

svg {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

footer svg {
  width: 1.3em;
  display: inline;
}

.carousel * {
  box-sizing: border-box;
}

.carousel ol, .carousel li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.carousel {
  position: relative;
  min-height: 20em;
}

.carousel__viewport {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.carousel__slide {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
}

.carousel__slide:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-40%,70px);
  font-size: 2em;
}

.carousel__snapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  scroll-snap-align: center;
  display: grid;
  place-content: center;
  justify-items: center;
}

.carousel__navigation {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}

.carousel__navigation-list,
.carousel__navigation-item {
  display: inline-block;
}

.carousel__navigation-button {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--brand-light-grey-2);
  border-radius: 50%;
  font-size: 0;
  transition: transform 0.1s;
  box-shadow: none;
}

.carousel__navigation-button:hover,
.carousel__navigation-button.active {
  background-color: var(--brand-light-grey);
}

.carousel::before,
.carousel::after {
  position: absolute;
  top: 0;
  margin-top: 37.5%;
  width: 4rem;
  height: 4rem;
  transform: translateY(-50%);
  border-radius: 50%;
  font-size: 0;
  outline: 0;
}

.carousel::before {
  left: -1rem;
}

.carousel::after {
  right: -1rem;
}

.review-stars {
  color: rgb(252,188,5);
  font-style: italic;
}

.review-text {
  font-weight: bold;
  font-style: italic;
  padding: 1em;
  text-align: center;
}

.review-text::before {
  content: "“";
}

.review-text::after {
  content: "”";
}

.reviewer {
  color: var(--brand-green-grey);
  font-style: italic;
  font-size: small;
  display: grid;
  justify-items: center;
}

.reviewer img {
  width: 32px;
}

.facebook-img {
  border-radius: 25px;
}

.carousel__slide {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from { opacity: .4 }
  to { opacity: 1 }
}

#web-dev {
  color: grey;
  font-size: smaller;
}

#services {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

@media only screen and (min-width: 1024px) {
  #services {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  display: grid;
  justify-items: center;
  box-shadow: unset;
}

.card__cover {
  width: 60%;
  max-height: 90px;
}

.card__cover svg {
  fill: var(--brand-green-2);
  filter: contrast(0.4);
}

.card__cover:not(.no-hov) svg:hover {
  fill: var(--brand-green);
  filter: contrast(1);
}

.card__content {
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.5em;
  text-align: center;
  place-self: stretch
}

.card:hover:not(.no-hov) {
  color: var(--brand-green);
}

.centre-btns {
  display: grid;
  place-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-columns: repeat(1, 1fr);
}

.centre-btns button {
  max-width: unset;
}

.centre-text {
  text-align: center;
}

p.centre-text {
  text-align: center;
  margin: 1em;
}

h2.centre-text {
  line-height: 1.2em;
}

h3.centre-text {
  padding-top: 1em;
}

em {
  font-weight: bold;
}

.page-content {
  margin-left: 10vw;
  margin-right: 10vw;
}

@media only screen and (min-width: 1024px) {
  .page-content {
    margin-left: calc(calc(100vw - 38.5em) / 2);
    margin-right: calc(calc(100vw - 38.5em) / 2);
  }
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid var(--border-color);
  margin: unset;
  margin-top: .5em;
  padding: 0;
  width: 100%;
}

#menuToggle {
  display: block;
  position: relative;
  z-index: 1;
  user-select: none;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;

  /* hide this, while still being clickable */
  opacity: 0;
  /* and place it over the hamburger */
  z-index: 2;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: var(--brand-black);
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* Transform all the slices of hamburger into a cross. */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
}

/* But let's hide the middle one. */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/* And the last one should go the other direction */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  width: 100vw;
  background-color: #fff;

  list-style-type: none;

  /* to stop flickering of text in safari */
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  left: 65px;

  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

  padding: 100px;
  text-align: center;
  display: none;
}

#menu li {
  padding: 10px 0;
  font-size: 1.375em;
}

/* Slide it in from the right */
#menuToggle input:checked ~ ul {
  transform: translate(-100vw, 0);
  display: block;
}

@media only screen and (min-width: 1024px) {
  #menuToggle {
    display: none;
  }

  #nav-list {
    display: flex;
  }
}

#contact-form-sent {
  display: none;
}

#sendBtn:disabled,
#sendBtn:disabled:hover {
  background-color: grey;
  cursor: unset;
}
